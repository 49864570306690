/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { DataGrid, GridDeleteIcon } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";
import { IconButton } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import InfoIcon from "@mui/icons-material/Info";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Edit } from "@mui/icons-material";
import Modal from "react-modal";
import { IoMdCloseCircle } from "react-icons/io";
import moment from "moment";
import {
  createInterestlead,
  destroyInterestlead,
  updateInterestlead,
  viewallInterestlead,
  viewByidInterestlead,
} from "../Api/interestlead";

const Interestlead = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [disablebtn, setdisablebtn] = useState(false);
  const [updatestatus, setupdatestatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [newmodel, setnewmodel] = useState(false);
  const [singlelead, setsinglelead] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 120,
      renderCell: (params) => (
        <>
          <h1>{params.row.name}</h1>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 200,
      renderCell: (params) => (
        <>
          <h1>{params.row.email}</h1>
        </>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 120,
      renderCell: (params) => (
        <>
          <h1>{params.row.phone}</h1>
        </>
      ),
    },

    {
      field: "leaddate",
      headerName: "Lead Date",
      width: 100,
      renderCell: (params) => (
        <>
          <h1>{params.row.leaddate}</h1>
        </>
      ),
    },

    {
      field: "course",
      headerName: "Course",
      width: 220,
      renderCell: (params) => (
        <>
          <h1>{params.row.course}</h1>
        </>
      ),
    },
    {
      field: "why_do_you_want",
      headerName: "Why do you want this job?",
      width: 200,
      renderCell: (params) => (
        <>
          <h1>{params.row.why_do_you_want}</h1>
        </>
      ),
    },
    {
      field: "can_you_work",
      headerName: "Can you work from our Coimbatore office",
      width: 200,
      renderCell: (params) => (
        <>
          <h1>{params.row.can_you_work}</h1>
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <>
          <select
            defaultValue={params.row.status}
            onChange={(e) => {
              changestatus(e.target.value, params.row.data);
            }}
            className="border-2 p-2 rounded"
          >
            <option value="">Status</option>
            <option
              value="New"
              selected={params.row.status == "New" || params.row.status == null}
            >
              New
            </option>
            <option value="Reviewed">Reviewed</option>
            <option value="Non Quality">Non Quality</option>
            <option value="Quality">Quality</option>
          </select>
        </>
      ),
    },
    {
      field: "review",
      headerName: "Reviewed By",
      width: 150,
      renderCell: (params) => (
        <>
          <select
            defaultValue={params.row.review}
            onChange={(e) => {
              changereview(e.target.value, params.row.data);
            }}
            className="border-2 p-2 rounded"
          >
            <option value="">Select</option>
            <option value="Vishwajeet">Vishwajeet</option>
            <option value="Palanivel">Palanivel</option>
            <option value="Karan">Karan</option>
          </select>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton>
            <Edit
              onClick={() => {
                editbtn(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <GridDeleteIcon
              onClick={() => {
                deletebtn(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <InfoIcon
              onClick={() => {
                viewbtn(params.row.data);
              }}
            />
          </IconButton>
        </>
      ),
    },
  ];
  const changestatus = async (e, id) => {
    await updateInterestlead({ id: id, status: e });
    getallleaddata();
  };
  const changereview = async (e, id) => {
    await updateInterestlead({ id: id, review: e });
    getallleaddata();
  };

  useEffect(() => {
    getallleaddata();
  }, [fromDate, toDate]);

  const getallleaddata = async () => {
    try {
      const alllead = await viewallInterestlead();
      if (alllead.length !== 0) {
        let alldata = [];
        for (let i = 0; i < alllead.length; i++) {
          alldata.push({
            id: i + 1,
            name: alllead[i].name || "-",
            email: alllead[i].email || "-",
            phone: alllead[i].phone || "-",
            data: alllead[i].id || "-",
            status: alllead[i].status || "-",
            review: alllead[i].review || "-",
            course: alllead[i].course || "-",
            why_do_you_want: alllead[i].why_do_you_want || "-",
            can_you_work: alllead[i].can_you_work || "-",
            leaddate: moment(alllead[i].createdAt).format("DD-MM-YYYY"),
          });
        }

        // Filter data by date range
        if (fromDate && toDate) {
          alldata = alldata.filter((item) => {
            const date = moment(item.leaddate, "DD-MM-YYYY");
            return date.isBetween(
              moment(fromDate),
              moment(toDate),
              "day",
              "[]"
            );
          });
        }

        // Sort the data by 'leaddate'
        alldata.sort((a, b) => {
          const dateA = moment(a.leaddate, "DD-MM-YYYY");
          const dateB = moment(b.leaddate, "DD-MM-YYYY");
          return dateB - dateA; // Descending order; for ascending use dateA - dateB
        });

        setSelectedRows(alldata);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deletebtn = async (e) => {
    var deletedata = await destroyInterestlead({ id: e });
    if (deletedata === "Deleted Successfully") {
      getallleaddata();
    }
  };
  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    phone: "",
    review: "",
    status: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const updatbtn = async () => {
    formdata["id"] = updateid;
    if (formdata.name.length === 0) {
      toast.error("Please enter Full Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      var updatedata = await updateInterestlead(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Lead Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getallleaddata();
          setcreatestatus(false);
        }, 2000);
        setformdata({
          name: "",
          email: "",
          phone: "",
          review: "",
          status: "",
        });
        setupdateid(null);
        setcreatestatus(false);
        setupdatestatus(false);
        setdisablebtn(false);
      }
    }
  };
  const savebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Full Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      var cretedata = await createInterestlead(formdata);
      if (cretedata.message === "SUCCESS") {
        toast.success("Lead added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getallleaddata();
          setcreatestatus(false);
          setdisablebtn(false);
        }, 2000);
      }
    }
  };
  const editbtn = async (data) => {
    var singledata = await selectedRows.filter((item) => {
      return item.data == data;
    });
    if (singledata.length !== 0) {
      setformdata({
        name: singledata[0].name,
        email: singledata[0].email,
        phone: singledata[0].phone,
      });
      setupdateid(data);
      setupdatestatus(true);
      setcreatestatus(true);
    }
  };
  const viewbtn = async (data) => {
    var viewcourse = await viewByidInterestlead({ id: data });
    setsinglelead(viewcourse);
    setnewmodel(true);
  };
  function closemodel() {
    setnewmodel(false);
  }

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };
  return (
    <>
      {createstatus === false ? (
        <div className="w-full px-14 min-h-screen">
          <div className="flex justify-between py-5 items-center">
            <h1 className="text-2xl py-4 font-black">Interested Lead User</h1>
          </div>
          <div className="flex gap-4 mb-4">
            <div className="mb-4">
              <label className="block mb-1">From Date</label>
              <input
                type="date"
                className="border-2 p-2 border-gray-300 rounded w-full"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">To Date</label>
              <input
                type="date"
                className="border-2 p-2 border-gray-300 rounded w-full"
                value={toDate}
                onChange={handleToDateChange}
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <DataGrid
              rows={selectedRows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              align={"center"}
              pageSizeOptions={[10, 20, 50]}
              // checkboxSelection
            />
          </div>
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setcreatestatus(false);
              setformdata({
                name: "",
                email: "",
                phone: "",
              });
              setupdateid(null);
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">Add New Lead</h1>
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Full Name
                  </h1>
                  <input
                    placeholder="First Name"
                    type="text"
                    name="name"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.name}
                  />
                </div>

                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Email
                  </h1>
                  <input
                    placeholder="Email"
                    type="text"
                    name="email"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.email}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Phone Number
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="text"
                    name="phone"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.phone}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              {updatestatus === true ? (
                disablebtn === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait..
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={updatbtn}
                  >
                    Update
                  </button>
                )
              ) : disablebtn === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait..
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtn}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />

      <Modal
        isOpen={newmodel}
        onRequestClose={closemodel}
        className="accountmodel"
      >
        <div className="flex justify-between items-center">
          <h1>lead Information</h1>
          <IoMdCloseCircle onClick={closemodel} className="h-8 w-8" />
        </div>
        <hr className="my-2 border border-black" />
        {singlelead.length !== 0 ? (
          <div className="mt-5">
            <div className="flex gap-4 mt-3">
              <h1 className="font-semibold">Name : </h1>
              <h2>{singlelead[0].name}</h2>
            </div>
            <div className="flex gap-4 mt-3">
              <h1 className="font-semibold">Email : </h1>
              <h2>{singlelead[0].email}</h2>
            </div>
            <div className="flex gap-4 mt-3">
              <h1 className="font-semibold">Phone Number : </h1>
              <h2>{singlelead[0].phone}</h2>
            </div>

            <div className="flex gap-4 mt-3">
              <h1 className="font-semibold">Profile : </h1>
              <h2>{singlelead[0].role === null ? "-" : singlelead[0].role}</h2>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default Interestlead;
