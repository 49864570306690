/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";
import "./Batch.css";
import moment from "moment";
import { viewallBatch_log } from "../../Api/batch_log";

const Batchlog = () => {
  const [batchlogdata, setbatchlogdata] = useState([]);
  const [allbatchlogdata, setallbatchlogdata] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  useEffect(() => {
    getStaffdata();
  }, []);

  const getStaffdata = async () => {
    var alldata = await viewallBatch_log();
    if (alldata.length !== 0) {
      setallbatchlogdata(alldata);
      var alldatanew = [];
      for (var i = 0; i < alldata.length; i++) {
        if (alldata[i].batchdata !== null) {
          alldatanew.push({
            id: i + 1,
            data: alldata[i].id,
            batch: alldata[i].batchdata.name,
            message: alldata[i].message,
            date: moment(alldata[i].createdAt).format("YYYY-MM-DD - hh:MM A"),
          });
        }
      }
      setbatchlogdata(alldatanew);
    } else {
      setbatchlogdata([]);
    }
  };

  const columns = [
    // { field: "id", headerName: "id", width: 100 },
    {
      field: "batch",
      headerName: "Batch",
      width: 200,
      renderCell: (params) => (
        <>
          <h1>Batch: {params.row.batch}</h1>
        </>
      ),
    },

    {
      field: "message",
      headerName: "Message",
      width: 700,
      renderCell: (params) => (
        <>
          <h1>{params.row.message}</h1>
        </>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      renderCell: (params) => (
        <>
          <h1>{params.row.date}</h1>
        </>
      ),
    },
  ];

  const handleGenerateReport = () => {
    if (!startDate || !endDate) {
      alert("Please fill all fields.");
      return;
    }
    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999);
    const filteredData = allbatchlogdata.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return createdAtDate >= start && createdAtDate <= end;
    });
    if (filteredData.length !== 0) {
      var alldatanew = [];
      for (var i = 0; i < filteredData.length; i++) {
        if (filteredData[i].batchdata !== null) {
          alldatanew.push({
            id: i + 1,
            data: filteredData[i].id,
            batch: filteredData[i].batchdata.name,
            message: filteredData[i].message,
            date: moment(filteredData[i].createdAt).format(
              "YYYY-MM-DD - hh:MM A"
            ),
          });
        }
      }
      setbatchlogdata(alldatanew);
    }
  };
  const Cancelbtn = () => {
    if (allbatchlogdata.length !== 0) {
      var alldatanew = [];
      for (var i = 0; i < allbatchlogdata.length; i++) {
        if (allbatchlogdata[i].batchdata !== null) {
          alldatanew.push({
            id: i + 1,
            data: allbatchlogdata[i].id,
            batch: allbatchlogdata[i].batchdata.name,
            message: allbatchlogdata[i].message,
            date: moment(allbatchlogdata[i].createdAt).format(
              "YYYY-MM-DD - hh:MM A"
            ),
          });
        }
      }
      setbatchlogdata(alldatanew);
    }
  };

  return (
    <div>
      <div className="px-8 min-h-screen">
        <div className="flex justify-between py-5 items-center">
          <h1 className="text-2xl py-4 font-black">Batch Log</h1>
          <div className="flex gap-4 items-center mt-5 items-center">
            <div className="form-group flex flex-col">
              <label>Start Date: </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="input-field border-2 border-gray-500 rounded p-2"
              />
            </div>

            <div className="form-group flex flex-col">
              <label>End Date: </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="input-field border-2 border-gray-500 rounded p-2"
              />
            </div>

            <button
              onClick={handleGenerateReport}
              className="bg-black rounded py-2 px-6 text-white mt-5"
            >
              Search
            </button>
            <button
              onClick={() => {
                setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
                setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
                setbatchlogdata([]);
                Cancelbtn();
              }}
              className="bg-red-600 rounded py-2 px-6 text-white mt-5"
            >
              Cancel
            </button>
          </div>
        </div>
        <DataGrid rows={batchlogdata} columns={columns} />
      </div>
    </div>
  );
};

export default Batchlog;
